
import { defineComponent } from 'vue';
import MessagesKeyboard from '@/components/Messages/MessagesKeyboard.vue';
import MessagesStream from '@/components/Messages/MessagesStream.vue';
import { orderMessages } from '@/services/messages';
import { IonButton } from '@ionic/vue';

export default defineComponent({
    name: 'MessagesOrder',
    data:()=>({
        gxButton: 'Escalate to GX',
        markReadTimeOut: 0,
        pollingInterval: undefined as undefined|number,
    }),
    components: {
        MessagesKeyboard,
        MessagesStream,
        IonButton
    },

    props: {
        orderId: Number,
        referenceNumber: String,
        customerName: String,
    },

    setup(props) {
        const msgService = { ...orderMessages(props.referenceNumber) };

        // Poll when marked as escalated
        if (msgService.conversationInfo?.value?.isEscalated) {
            msgService.refreshMessages();
        }

        // wait 5 seconds to mark convo as read
        if (props.referenceNumber) {
            setTimeout(() => msgService.markConvoRead(props.referenceNumber).then(), 5000);
        }

        return { ...msgService };
    },
    watch: {
        conversationInfo(newVal) {
            if (newVal?.value?.isEscalated) {
                this.pollingInterval = setInterval(() => this.refreshMessages() , 5000);
            } else {
                clearInterval(this.pollingInterval);
            }

        }
    },
    beforeUnmount() {
        if (this.pollingInterval) clearInterval(this.pollingInterval);

    }
});
