
import { computed, defineComponent, toRefs, inject } from 'vue';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonPage } from '@ionic/vue';
import { IonIcon } from '@ionic/vue';
import { chevronBackSharp } from 'ionicons/icons';
import { shopOrders } from '@/services/firebase';
import { FbOrderI } from '@/services/firebase/FirebaseService';
import { Store } from '@/stores';
import useConfigs from '@/services/config';
import useOrderData from '@/composables/useOrderData';
import ActionButton from '@/components/Buttons/ActionButton.vue';
import MessagesOrder from '@/components/Messages/MessagesOrder.vue';

export default defineComponent({
    name: 'OrderDetail',

    components: {
        ActionButton,
        IonButton,
        IonContent,
        IonIcon,
        IonPage,
        MessagesOrder,
    },

    setup() {
        const store = inject('store') as Store;
        const { showOrderGuestMessage } = toRefs(store.state);
        const route = useRoute();
        const { id } = route.params;
        const orderNumber = Array.isArray(id) ? id[0] : id;

        const order = computed(
            () => shopOrders.value.all.find(
                (order: FbOrderI) => order.orderDetails.orderKey === orderNumber
            )
        );
        const isLoading = computed(() => shopOrders.value.all.length === 0);

        return {
            showOrderGuestMessage,
            configs: useConfigs(),
            chevronBackSharp,
            isLoading,
            order,
            ...useOrderData(order),
        };
    },
});
